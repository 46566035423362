import { GRAPHQL } from './http.client'
import userMonitoring, { ErrorTypes } from '@/mixins/userMonitoring'
import ServiceGroupsQuery from '@/api/queries/ServiceGroups.graphql'
import ServiceQuery from '@/api/queries/Service.graphql'
import {
  MotoritOnlineQueriesServiceArgs,
  MotoritOnlineQueriesServiceGroupsArgs,
  ServiceGroup,
  WorkshopService,
} from '@/types/generated-types'
import { useSettings } from '@/store/settings.store'
import { useVehicle } from '@/store/vehicle.store'

export const getServiceGroupsRequest = (): Promise<ServiceGroup[]> => {
  const settingStore = useSettings()
  const vehicleStore = useVehicle()
  const payload = {
    languageCode: settingStore.culture,
    siteId: settingStore.siteData.siteId,
    carMake: vehicleStore.vehicle.siteCarMake.value,
    model: vehicleStore.vehicle.model,
    year: vehicleStore.vehicle.year,
    classificationCode: vehicleStore.vehicle.classificationCode,
    hasTireHotel: vehicleStore.vehicle.hasTireHotel,
    typeHigh: vehicleStore.vehicle.typeHigh,
  } as MotoritOnlineQueriesServiceGroupsArgs
  return GRAPHQL.query<any>({
    query: ServiceGroupsQuery,
    variables: payload,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  })
    .then((response) => {
      if (response.errors) {
        console.log(
          `${ErrorTypes.graphQL}: serviceGroups failed with error: ${JSON.stringify(
            response.errors
          )}`
        )
        userMonitoring().trackError(
          `${ErrorTypes.graphQL}: serviceGroups failed with error: ${JSON.stringify(
            response.errors
          )}`
        )
      }
      return response.data.serviceGroups as ServiceGroup[]
    })
    .catch((error) => {
      console.log(`${ErrorTypes.graphQL}: serviceGroups failed with error: ${error}`)
      userMonitoring().trackError(
        `${ErrorTypes.graphQL}: serviceGroups failed with error: ${error}`
      )
      return [] as ServiceGroup[]
    })
}

export const getServiceRequest = (serviceIdList: string[]): Promise<WorkshopService> => {
  const settingStore = useSettings()
  const vehicleStore = useVehicle()
  const payload = {
    siteId: settingStore.siteData.siteId,
    carMake: vehicleStore.vehicle.make,
    model: vehicleStore.vehicle.model,
    year: vehicleStore.vehicle.year,
    classificationCode: vehicleStore.vehicle.classificationCode,
    hasTireHotel: vehicleStore.vehicle.hasTireHotel,
    serviceIdList: serviceIdList,
  } as MotoritOnlineQueriesServiceArgs
  return GRAPHQL.query<any>({
    query: ServiceQuery,
    variables: payload,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  })
    .then((response) => {
      if (response.errors) {
        console.log(
          `${ErrorTypes.graphQL}: service failed with error: ${JSON.stringify(response.errors)}`
        )
        userMonitoring().trackError(
          `${ErrorTypes.graphQL}: service failed with error: ${JSON.stringify(response.errors)}`
        )
      }
      return response.data.service as WorkshopService
    })
    .catch((error) => {
      console.log(`${ErrorTypes.graphQL}: service failed with error: ${error}`)
      userMonitoring().trackError(`${ErrorTypes.graphQL}: service failed with error: ${error}`)
      return {} as WorkshopService
    })
}
