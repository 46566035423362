import { getCarMakeNamesRequest } from '@/api/car-make.service'
import { getVehicleInformationGraphQL } from '@/api/vehicle.service'
import objectIsEmpty from '@/mixins/sharedFunctions/objectIsEmpty'
import { CarMake } from '@/models/Vehicle/carMake'
import { Vehicle } from '@/models/Vehicle/Vehicle'
import { wheelWear } from '@/models/Vehicle/wheelWear'
import { defineStore } from 'pinia'
import { useBooking } from './booking.store'
import { useSettings } from './settings.store'
import { useService } from './service.store'
import { useRouter } from '@/router'
import { useText } from './text.store'
import { ServiceConfigurationInputType, WorkshopService } from '@/types/generated-types'

interface VehicleState {
  regNumberIsDone: boolean
  vehicle: Vehicle
  vehicleDataIsLoading: boolean
  carMakes: CarMake[]
  isTransportSelected: boolean
  wheelWear: wheelWear
  campaignCode: string | undefined
}

export const useVehicle = defineStore('vehicle', {
  state: () =>
    ({
      regNumberIsDone: false,
      vehicle: {} as Vehicle,
      vehicleDataIsLoading: false,
      carMakes: [] as CarMake[],
      isTransportSelected: false as boolean,
      wheelWear: {} as wheelWear,
      campaignCode: undefined,
    } as VehicleState),
  actions: {
    onRender() {
      const bookingStore = useBooking()
      bookingStore.resetBooking()
    },
    async complete(registrationNumber: string){
      const serviceStore = useService()
      const bookingStore = useBooking()
      const textStore = useText()
      const settingsStore = useSettings()

      if (!objectIsEmpty(bookingStore.vehicle)) {
        bookingStore.clearBookedWheels()
        serviceStore.errorCarmakeServiceNotMatch = false
      }
      this.vehicleDataIsLoading = true

      const regNrRegExp = new RegExp(settingsStore.settings.registrationNumberRegexp)
      if (!regNrRegExp.test(registrationNumber) || registrationNumber === '') {
        bookingStore.vehicle = {} as Vehicle
        return false
      }

      return this.fetchVehicleByRegistrationNumber(registrationNumber)
        .then(async () => {
          this.vehicleDataIsLoading = false
          if (objectIsEmpty(this.vehicle)) {
            return false
          } else {
            textStore.fetchUsp()
            bookingStore.clearBookedWheels()
            serviceStore.errorCarmakeServiceNotMatch = false

            bookingStore.vehicle = this.vehicle

            this.regNumberIsDone = true

            return true
          }
        })
        .catch((e) => false)
    },

    async goToServiceStep(carMakeWasChanged: boolean) {
      const router = useRouter()
      const vehicleStore = useVehicle()
      const serviceIdFromQueryParams = carMakeWasChanged ? undefined : router.currentRoute.value.query.serviceid

      router.push({
        name: 'service',
        query: {
          regnr: vehicleStore.vehicle.registrationNumber,
          ...(serviceIdFromQueryParams ? { serviceid: serviceIdFromQueryParams } : {}),
        },
      })
    },

    registrationNumberFormat(registrationNumber: string) {
      const settingsStore = useSettings()

      return registrationNumber
        .replace(/\s/g, '')
        .substring(0, settingsStore.settings.registrationNumberLength)
    },

    async fetchVehicleByRegistrationNumber(registrationNumber: string): Promise<void> {
      const bookingStore = useBooking()
      this.vehicleDataIsLoading = true

      return await getVehicleInformationGraphQL(
        this.registrationNumberFormat(registrationNumber)
      ).then((vehicleResponse) => {
        if (!vehicleResponse) {
          this.vehicle = {} as Vehicle
        } else {
          this.vehicle = {
            registrationNumber: registrationNumber,
            make: vehicleResponse.make,
            siteCarMake: vehicleResponse.siteCarMake,
            model: vehicleResponse.model,
            year: vehicleResponse.year,
            isTransportCar: vehicleResponse.isTransportCar,
            typedMileage: 0,
            uuid: vehicleResponse.campaignCustomer?.uuid,
            hasSubmittedInfo: vehicleResponse.campaignCustomer?.hasSubmittedInfo ?? false,
            hasTireHotel: vehicleResponse.tireInformation.hasTireHotel,
            classificationCode: vehicleResponse.classificationCode,
            typeHigh: vehicleResponse.typeHigh,
            hasSubscription: vehicleResponse.tireInformation.hasSubscription ?? false,
          }
        }

        this.wheelWear = vehicleResponse?.tireInformation ?? {}
        this.vehicleDataIsLoading = false
      })
    },

    async getCarMakeNames(): Promise<void> {
      const settingsStore = useSettings()

      await getCarMakeNamesRequest(settingsStore.culture).then((carMakes) => {
        this.carMakes = carMakes
      })
    },

    getRegistrationNumberPresentation(): string {
      const registrationNumber = this.vehicle.registrationNumber
      let presentation = ''
      if (registrationNumber) {
        presentation = registrationNumber.slice(0, 3) + ' ' + registrationNumber.slice(3)
      }
      return presentation
    },

    resetState() {
      this.vehicle = {} as Vehicle
    },

    getTireWearArray() {
      if (!this.wheelWear?.tiresOnCar) {
        return []
      }

      const wheels = [...this.wheelWear.tiresOnCar].sort((a, b) => {
        const x = a.position
        const y = b.position
        return x < y ? -1 : x > y ? 1 : 0
      })
      return wheels.map((wheel) => wheel.treadDepth)
    },

    getWearThresholds() {
      if (this.wheelWear.season == 'sommar') {
        return [1.6, 3.9, 4.0]
      } else {
        return [3.0, 4.9, 5.0]
      }
    },

    wheelSalesIsAvailable(): boolean {
      return (
        !objectIsEmpty(this.wheelWear) &&
        this.wheelWear.hasTireHotel &&
        this.wheelWear.numberOfWornTires > 0 &&
        this.wheelWear.tireSuggestions != null
      )
    },

    showWheelWearSales() {
      const settingsStore = useSettings()
      if (
        settingsStore.siteData.culture !== 'nb-NO' &&
        this.hasTireHotelAndIsTireHotelService() &&
        !objectIsEmpty(this.wheelWear) &&
        this.wheelWear.numberOfWornTires > 0 &&
        this.wheelWear.tireSuggestions != null &&
        !this.vehicle.hasSubmittedInfo &&
        !this.vehicle.hasSubscription 
      ) {
        return true
      }
      return false
    },

    wheelsRemainingUntilIlleagal() {
      return Math.min(...this.getTireWearArray()) - this.getWearThresholds()[0] + 0.1
    },

    isTireHotel(): boolean {
      return !!this.wheelWear.branchCode
    },
    hasTireHotelAndIsTireHotelService(workshopService?: WorkshopService): boolean {
      const bookingStore = useBooking()
      if (
        (this.wheelWear.branchCode != '' || this.wheelWear.hasTireHotel) &&
        (!objectIsEmpty(workshopService)
          ? workshopService?.isTireHotelService
          : bookingStore.workshopService.isTireHotelService)
      ) {
        return true
      }

      return false
    },
    hasWheelSalesServiceConfigInput() {
      const bookingStore = useBooking()
      return (
        (bookingStore.workshopService.serviceConfigurationInputs?.findIndex(
          (config) =>
            config.serviceConfigurationInputType === ServiceConfigurationInputType.WheelSales
        ) ?? -1) >= 0
      )
    },
    // evaluateCampaignCode(campaignCode: string) {
    //   this.campaignCode = campaignCode
    // },
  },
})
