import { createApp } from 'vue'
import App from './App.vue'
import { useRouter } from './router'
// eslint-disable-next-line import/no-unresolved
import { registerSW } from 'virtual:pwa-register'
import '@/assets/scss/global.scss'
import { vMaska } from 'maska'
import { createPinia } from 'pinia'
import { useText } from './store/text.store'
import { useVehicle } from './store/vehicle.store'
import { useSettings } from './store/settings.store'

// @ts-ignore
import { makeServer } from './mirage/server'
import userMonitoring from './mixins/userMonitoring'
import { useConfigCat } from './store/config-cat.store'
import '@inhouse/motorit-component-library/dist/style.css'

// if (process.env.NODE_ENV === 'localse') {
//   makeServer()
// }

const initApp = async () => {
  const app = createApp(App)
  app.directive('maska', vMaska)
  app.use(useRouter())
  app.use(createPinia())
  /*   app.use(Bricks) */

  useConfigCat().init()
  userMonitoring().init()

  registerSW()
  // application
  const settingsStore = useSettings()
  settingsStore.culture = document.documentElement.lang
  settingsStore.fetchSiteData()

  // global text
  const textStore = useText()

  textStore.fetchAllTexts()

  textStore.fetchUsp()

  //settings
  settingsStore.fetchSettings()

  // makes
  const vehicleStore = useVehicle()
  vehicleStore.getCarMakeNames()
  app.mount('#app')
}

initApp()
